import createObject from './create.js'
import common from './common.js'
import Hammer from 'hammerjs'
import { Toast } from 'vant'
export default (id, fabric, vm) => {
  const fabricObject = createObject(id, fabric, vm)
  const actions = common(fabricObject, vm)
  const instance = actions.fabricInstance

  let isDragging = false
  let isScaling = false
  let selectedObj = {}
  // 绑定事件
  instance.on('object:modified', e => {
    fabricObject.updateCanvasStack()
    vm && vm.changeEditorPanel(e.target)
  })

  // 松开事件
  instance.on('mouse:up', e => {
    try {
      if (e && e.target && e.target.selectable) {
        isDragging = false
        isScaling = false
        // m 版 需要做特殊处理
        if (vm.isMobile) {
          instance.discardActiveObject()
          actions.setActiveSelect(e.target)
        }

        if (selectedObj !== e.target) {
          vm && vm.changeEditorPanel(e.target)

          selectedObj = e.target
          selectedObj.setControlsVisibility({
            bl: false,
            mb: false,
            ml: false,
            mt: false,
            tl: false,
            tr: false,
          })
          if (selectedObj && typeof selectedObj.set === 'function') {
            selectedObj.set({
              lockMovementX: false,
              lockMovementY: false,
              lockRotation: false,
            })
          }
        }

        // 禁止超出画布
        if (e && e.target !== null && e.target.getBoundingRect()) {
          // 获取画布视口边界
          const canvasBoundaries = instance.calcViewportBoundaries()
          // 选中元素的边界
          const objBoundingRect = e.target.getBoundingRect()

          // 【公式1】居左
          if (objBoundingRect.left < canvasBoundaries.tl.x) {
            e.target.left = canvasBoundaries.tl.x + objBoundingRect.width / 2
          }

          // 【公式2】居右
          if (objBoundingRect.left + objBoundingRect.width > canvasBoundaries.br.x) {
            e.target.left = canvasBoundaries.br.x - objBoundingRect.width / 2
          }

          // 【公式3】居上
          if (objBoundingRect.top < canvasBoundaries.tl.y) {
            e.target.top = canvasBoundaries.tl.y + objBoundingRect.height / 2
          }

          // 【公式4】居下
          if (objBoundingRect.top + objBoundingRect.height > canvasBoundaries.br.y) {
            e.target.top = canvasBoundaries.br.y - objBoundingRect.height / 2
          }

          // 用于对象进行变换（例如旋转、缩放、平移）后
          // 更新对象的坐标和尺寸信息以匹配其当前的变换。
          e.target.setCoords()
          // 刷新画布
          // instance.requestRenderAll()
        }
      } else if (!e.target) {
        // 点击到canvas中图片的渲染区域，重置图层选中状态为 未选中
        selectedObj = {}
        vm && vm.resetEditorTools()
      }
    } catch (error) {
      // Toast('单击事件失败')
    }
  })

  // 按下事件
  instance.on('mouse:down', e => {
    console.log('e.target', e.target?.src)
    if (e.target) {
      isDragging = true
      e.target.lockScalingX = false
    }
  })
  // 控制角缩放
  instance.on('object:scaling', e => {
    if (e.target && e.target.scaleX) {
      isScaling = true
      const obj = e.target
      const canvasWidth = instance.width
      const canvasHeight = instance.height

      // 获取当前元素的尺寸
      const objWidth = obj.getScaledWidth()
      const objHeight = obj.getScaledHeight()

      // 检查是否超出画布边界
      const width = canvasWidth / obj.width
      const height = canvasHeight / obj.height
      const scale = Math.max(width, height)
      // 如果超出边界
      if (objWidth > canvasWidth || objHeight > canvasHeight) {
        obj.scaleX = scale
        obj.scaleY = scale

        obj.lockScalingX = true
      } else {
        obj.lockScalingX = false
      }
    }
  })
  // 启用Pinch缩放手势 && typeof selectedObj.set === 'function'
  let originalScale = 1

  try {
    // 初始化Hammer.js
    const hammer = new Hammer(instance.upperCanvasEl)

    //开启缩放
    hammer.get('pinch').set({ enable: true })

    // 双击事件
    hammer.on('doubletap', e => {
      try {
        if (selectedObj && selectedObj.type === 'text') {
          vm && vm.changeTextPanel(selectedObj)
        }
      } catch (error) {
        // Toast('双击文本失败')
      }
    })

    // 缩放 -开始
    hammer.on('pinchstart', e => {
      try {
        if (selectedObj && selectedObj.scaleX) {
          isScaling = true
          originalScale = selectedObj.scaleX
        }
      } catch (error) {
        isScaling = false
        // Toast('缩放开始失败')
      }
    })

    // 缩放
    hammer.on('pinch', function (e) {
      try {
        const target = e.target
        // 计算新的缩放比例
        const newScale = originalScale * e.scale

        // 获取画布的宽度和高度
        const canvasWidth = instance.width
        const canvasHeight = instance.height

        // 计算对象的新宽度和高度
        const newWidth = selectedObj.width * newScale
        const newHeight = selectedObj.height * newScale
        const scale = 0
        // 检查是否超出画布边界
        if (newWidth > canvasWidth || newHeight > canvasHeight) {
          const width = canvasWidth / selectedObj.width
          const height = canvasHeight / selectedObj.height
          const scale = Math.max(width, height)
          // 如果超出边界
          selectedObj.scaleX = scale
          selectedObj.scaleY = scale
          selectedObj.setCoords()
          // 如果超出边界，则设置元素尺寸为画布边界的最大值
          selectedObj.lockScalingX = true
        } else {
          // 未超出
          selectedObj.lockScalingX = false
          selectedObj.scaleX = newScale
          selectedObj.scaleY = newScale
          selectedObj.setCoords()
          // 禁止旋转和移动
          selectedObj.set({
            lockRotation: true,
            lockMovementX: true,
            lockMovementY: true,
          })
        }
        fabricObject.setActiveSelect(selectedObj)
      } catch (error) {
        // Toast('缩放失败')
      }
    })

    //缩放结束
    hammer.on('pinchend', () => {
      try {
        isScaling = false
        // 禁止旋转和移动
        selectedObj.set({
          lockRotation: false,
          lockMovementX: false,
          lockMovementY: false,
        })
        instance.setActiveSelect(selectedObj)
        vm && vm.changeEditorPanel(selectedObj)
      } catch (error) {
        // Toast('缩放结束失败')
      }
    })
  } catch (error) {
    // Toast('缩放事件操作失败')
  }
  function animate() {
    fabric.util.requestAnimFrame(animate)

    if (isDragging || isScaling) {
      instance.renderAll()
    }
  }

  // 启动动画
  animate()
  return actions
}
