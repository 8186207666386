<!--
 * @Description: 亮度面板
 * @Author: 王立俊
 * @Date: 2023-06-14 11:48:24
 * @LastEditTime: 2023-08-22 09:59:09
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
-->
<template>
  <div class="editor-panel-transparent">
    <div class="editor-transparent-box">
      <i class="iconfont icon-luminance" />
      <van-slider
        v-model="brightness"
        :min="-150"
        :max="150"
        :step="1"
        class="slider"
        bar-height="4px"
        active-color="#4391FC"
        @change="onChange"
      />
      <div style="color: #4391fc">{{ brightness }}</div>
    </div>
    <div class="editor-bottom-btn">
      <i class="iconfont icon-close" @click="handleClose" />
      <div class="title">亮度</div>
      <i class="iconfont icon-success" @click="handleSuccess" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LuminancePanel',
  props: {},
  data() {
    return {
      brightness: 0
    }
  },
  methods: {
    // 修改透明度
    onChange(val) {
      this.$emit('onBrightness', val)
    },
    // 关闭
    handleClose() {
      this.$emit('close')
    },
    // 成功
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>

<style lang="scss" scoped>
i {
  font-size: 20px;
}
.editor-panel-transparent {
  height: 100px;
  background: #fff;
  .editor-transparent-box {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    .slider {
      margin: 0 20px;
    }
  }
}
</style>
