/*
 * @Description: canvas 操作相关
 * @Author: 王立俊
 * @Date: 2023-06-13 09:25:43
 * @LastEditTime: 2023-09-26 14:18:52
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
 */
import { createUniqueString } from '@/utils/util'
export default (fabricObject, vm) => {
  // toJSON包含自定义属性内容
  const toJsonWithParams = ['id', 'name', 'type', 'src', 'left', 'top', 'flag']

  const { fabricInstance } = fabricObject
  fabricObject.setToJsonWithParams(toJsonWithParams)

  // 数据渲染
  const addObjects = function (nodes) {
    const cloneJson = JSON.parse(JSON.stringify(nodes))
    loadDataToCanvas(cloneJson)
  }

  // 创建图片，dataList不传则为新增
  const createImage = function (obj, dataList) {
    if (dataList) {
      fabricObject.createImage(obj, 1)

      loadDataCallback(dataList)
    } else {
      const image = {
        id: createUniqueString(),
        src: obj.src,
        width: obj.width,
        height: obj.height,
        type: 'image',
        visible: true,
        opacity: 1,
        name: '图片',
      }

      fabricObject.createImage(image, 2)
    }
  }

  // 创建文本，dataList不传 则为新增/或者替换
  const createTxt = function (obj, dataList) {
    if (dataList) {
      fabricObject.createItext(obj, 1)

      loadDataCallback(dataList)
    } else {
      fabricObject.createItext(obj, 2)
    }
  }

  // 修改文本属性
  const changeTextConfig = function (key, value) {
    fabricObject.changeText(key, value)
  }

  // 加载数据到画布的回调函数，适用于 loadDataToCanvas方法
  const loadDataCallback = function (list) {
    list.shift()
    loadDataToCanvas(list)
  }

  // 处理加载过来的数据，递归加载
  const loadDataToCanvas = async function (dataList) {
    if (dataList && dataList.length > 0) {
      const obj = dataList[0]
      if (obj.type === 'image' || obj.type === 'icon') {
        // 图片
        setTimeout(() => {
          createImage(obj, dataList)
        }, 20)
      } else {
        // 文字
        setTimeout(() => {
          createTxt(obj, dataList)
        }, 20)
      }
    } else {
      setTimeout(() => {
        fabricObject.firstAddToState(true)
        fabricObject.updateCanvasStack()
      }, 500)
    }
  }

  // 选中元素事件
  const canvasObjectSelected = function () {}

  // 创建图标svg
  const createSvg = async obj => {
    const image = {
      id: createUniqueString(),
      src: obj.src,
      width: obj.width,
      height: obj.height,
      type: 'image',
      flag: 'icon',
      visible: true,
      opacity: 1,
      name: '图标',
    }
    fabricObject.createImage(image, 2)
  }
  // 更换图标 2 更换
  const updateSvg = obj => {
    console.log('obj', obj)
    fabricObject.createImage(obj, 2)
  }

  // 设置图片对齐角度
  const setImageDirection = function (type, currentActive) {
    const width = currentActive.scaleX ? currentActive.scaleX * currentActive.width : currentActive.width
    const height = currentActive.scaleY ? currentActive.scaleY * currentActive.height : currentActive.height
    switch (type) {
      case 'rightTop':
        currentActive.set('left', fabricInstance.getWidth() - width / 2)
        currentActive.set('top', height / 2)
        break
      case 'leftTop':
        currentActive.set('left', width / 2)
        currentActive.set('top', height / 2)
        break
      case 'center':
        currentActive.center()
        break
      case 'rightBottom':
        currentActive.set('left', fabricInstance.getWidth() - width / 2)
        currentActive.set('top', fabricInstance.getHeight() - height / 2)
        break
      case 'leftBottom':
        currentActive.set('left', width / 2)
        currentActive.set('top', fabricInstance.getHeight() - height / 2)
        break
      default:
        break
    }
    fabricInstance.requestRenderAll()
    fabricObject.updateCanvasStack()
  }

  // 修改图层顺序 0 置底 1 下移 2 上移 3 置顶
  const updateLevel = function (type) {
    switch (type) {
      case 0:
        fabricObject.toBottomLayer()
        break
      case 1:
        fabricObject.toNextLayer()
        break
      case 2:
        fabricObject.toLastLayer()
        break
      case 3:
        fabricObject.toTopLayer()
        break

      default:
        break
    }
  }
  // 设置选中项
  const setActiveSelect = function (order) {
    fabricObject.setActiveSelect(order)
  }

  // 导出json数据
  const toJSon = function () {
    return fabricObject.toJSon()
  }

  return {
    _config: fabricObject._config,
    toJSon,
    toPng: fabricObject.toPng,
    addObjects,
    setImageDirection,
    createTxt,
    createImage,
    createSvg,
    updateSvg,
    changeTextConfig,
    fabricInstance,
    setActiveSelect,
    undo: fabricObject.undo,
    redo: fabricObject.redo,
    loadFont: fabricObject.loadFont,
    initCanvas: fabricObject.initCanvas,
    updateCanvasStack: fabricObject.updateCanvasStack,
    getActiveObj: fabricObject.getActiveObj,
    getObjects: fabricObject.getObjects,
    getLevelList: fabricObject.getNewLevelList,
    getOpacity: fabricObject.getOpacity,
    setOpacity: fabricObject.setOpacity,
    getBrightness: fabricObject.getBrightness,
    setBrightness: fabricObject.setBrightness,
    updateLevel,
    copyLayer: fabricObject.copyLayer,
    delItem: fabricObject.delItem,
    canvasObjectSelected,
    loadDataCallback,
  }
}
