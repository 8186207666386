<!--
 * @Description: 图片裁剪面板
 * @Author: 王立俊
 * @Date: 2023-06-14 11:47:10
 * @LastEditTime: 2023-09-21 14:39:14
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
-->
<template>
  <div>
    <!-- 自定义裁剪组件 -->
    <image-crop
      ref="image-crop"
      :upload-loading="uploadLoading"
      :img-url="ruleForm.whitePhoneUrl"
      @after-upload="cropUpload"
      @close="close"
    ></image-crop>
  </div>
</template>

<script>
import imageCrop from './image-crop.vue'
export default {
  name: 'ImgCutPanel',
  components: {
    imageCrop,
  },
  props: {},
  data() {
    return {
      activeObj: {},
      uploadLoading: false,
      ruleForm: {
        whitePhotoId: '',
        whitePhoneUrl: null,
      },
    }
  },

  methods: {
    // 传入数据
    show(activeObj, cutImg) {
      this.activeObj = activeObj
      const ref = this.$refs['image-crop']
      ref.show(cutImg)
    },
    // 裁剪点击确定
    cropUpload(file) {
      this.uploadLoading = false
      this.$emit('cropUpload', file)
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.img-cut-panel {
  background-color: #fff;
  .panel-title {
    padding: 10px;
    font-size: 14px;
    line-height: 1;
    color: #999;
    text-align: center;
  }
  .editor-cut-content {
    height: 60px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cut-img-btn {
      width: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .desc {
        color: #999;
        font-size: 12px;
        display: block;
        padding-top: 3px;
      }
    }
    .slider-content {
      width: 250px;
      height: inherit;
      overflow-x: hidden;
      position: relative;
      .cut-img-wrap {
        height: 38px;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        align-content: center;
        justify-content: center;
        box-sizing: border-box;
        overflow: visible;
        transition: transform 0.2s ease-out;
        .scale {
          position: relative;
          .value {
            position: absolute;
            top: -16px;
            left: -5px;
            font-size: 10px;
          }
        }

        .large-scale {
          /* 大刻度样式 */
          height: 18px;
          text-align: center;
          border-left: 2px solid #ccc;
          margin-right: 6px;
        }

        .small-scale {
          /* 小刻度样式 */
          height: 14px;
          line-height: 16px;
          text-align: center;
          border-left: 2px solid #ccc;
          margin-right: 6px;
        }
        .trans-late {
          /* 滑过的刻度样式 */
          border-left: 2px solid #000;
        }
      }
      .cut-img-slider-middle {
        position: absolute;
        left: 48%;
        bottom: 21px;
        height: 18px;
        width: 2px;
        background: #262626;
      }
      .cut-img-slider-triangle {
        position: absolute;
        left: 48%;
        bottom: 12px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid #262626;
        margin-left: -3px;
      }
    }
    .slider-content:after {
      right: 0;
      background: linear-gradient(-90deg, rgba(255, 255, 255), rgba(255, 255, 255, 0));
    }
    .slider-content:before {
      left: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255), rgba(255, 255, 255, 0));
    }
    .slider-content:after,
    .slider-content:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 2;
      width: 100px;
    }
  }
  .editor-cut-footer {
    padding: 20px 15px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eee;
    align-items: center;
    i {
      font-size: 18px;
    }
    .desc {
      color: #999;
      font-size: 12px;
      display: block;
      padding-top: 3px;
    }
    .reset {
      color: #000;
    }
  }
}
</style>
