/*
 * @Description:
 * @Author: 王立俊
 * @Date: 2023-08-23 16:20:20
 * @LastEditTime: 2023-09-25 10:28:36
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
 */
/**
 * @description: 创建图片元素
 * @param {String} str 图片地址或者base64图片
 * @return {Promise} element 图片元素
 */
export function insertImgFile(str) {
  return new Promise(resolve => {
    const imgEl = document.createElement('img')
    imgEl.src = str
    imgEl.crossOrigin = 'anonymous'
    // 插入页面
    document.body.appendChild(imgEl)
    imgEl.onload = () => {
      resolve(imgEl)
    }
  })
}

/**
 * 创建唯一字符串
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * 创建滤镜数据
 * @param {*} sourceImg 活动对象
 * @param {*} type 滤镜参数，此处暂时未 亮度，其他属性本项目不涉及
 * @param {*} options 格外参数
 * @returns
 */
export function _createFilter(fabric, fabricInstance, sourceImg, type, options = null) {
  let filterObj
  const ImageFilter = fabric.Image.filters[type]
  if (ImageFilter) {
    filterObj = new ImageFilter(options)
    filterObj.options = options
    sourceImg.filters.push(filterObj)
  }
  sourceImg.applyFilters()
  // fabricInstance.requestRenderAll()
  return filterObj
}
