<!--
 * @Description: 富文本编辑框
 * @Author: 王立俊
 * @Date: 2023-06-14 11:48:36
 * @LastEditTime: 2023-09-16 17:38:59
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
-->
<template>
  <div class="editor-panel editor-panel-textarea">
    <van-overlay :show="true">
      <div class="wrapper-top">
        <i class="iconfont icon-close" @click="handleClose" />
        <i class="iconfont icon-success" @click="handleSuccess" />
      </div>
      <div class="wrapper-box" @click.stop>
        <textarea v-model="text" class="textarea" :rows="15" :placeholder="placeholder" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: 'TextareaModal',
  data() {
    return {
      text: null,
      placeholder: '点击编辑内容',
    }
  },
  methods: {
    show(text) {
      if (text === this.placeholder) {
        this.text = ''
      } else {
        this.text = text
      }
    },
    // 关闭
    handleClose() {
      this.$emit('close')
    },
    // 确认
    handleSuccess() {
      if (this.text) {
        this.$emit('success', this.text)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper-top {
  padding: 50px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    font-size: 24px;
    color: #fff;
  }
}
.wrapper-box {
  display: flex;
  align-items: center;
  justify-content: center;
  .textarea {
    margin: 0 30px;
    padding: 8px;
    text-align: left;
    width: 100%;
    border: none;
    resize: none;
    caret-color: #ffffff;
    color: #ffffff;
    font-size: 12px;
    background-color: rgba(0, 0, 0, -0.3);
    border: 1px solid #e8e8e8;
    border-radius: 8px;
  }
  textarea::-webkit-input-placeholder {
    padding: 0;
    font-size: 12px;
  }
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>
