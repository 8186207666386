/*
 * @Description: 二次封装fabric
 * @Author: 王立俊
 * @Date: 2023-06-13 09:25:49
 * @LastEditTime: 2023-10-07 11:23:18
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
 */

import { createUniqueString, _createFilter } from '@/utils/util'
import FontFaceObserver from 'fontfaceobserver'
export default (id = 'canvas', fabric, vm = {}) => {
  const zoom = vm.psdZoom > 1 ? 1 / vm.psdZoom : vm.psdZoom

  // 默认fabric 配置
  const _fabricConfig = {
    backgroundVpt: false,
    preserveObjectStacking: true,
    enableRetinaScaling: false,
    isTouchSupported: true,
    selection: false, // 取消框选
  }

  // 默认字体配置
  const defaultFontConfig = {
    id: createUniqueString(),
    type: 'text',
    name: '文本内容',
    editable: false,
    left: 0,
    top: 0,
    lineHeight: 1.5,
    letterSpacing: 0,
    fontSize: 80,
    fontFamily: '请选择字体',
    fontWeight: 'normal',
    fontStyle: 'normal',
    writingMode: 'horizontal-tb',
    textDecoration: 'none',
    color: '#333942',
    textAlign: 'left',
    text: '点击编辑内容',
    opacity: 1,
    backgroundColor: '',
  }

  // 撤销回退相关操作状态配置表
  const _config = {
    canvasStack: [], // 存储状态的json数据
    currentStateIndex: -1,
    undoStatus: false, // 撤销
    redoStatus: false, // 回退
    undoFinishedStatus: 1, // 撤销中状态
    redoFinishedStatus: 1, // 回退中状态
    undoBtnStatus: false, // 撤销按钮可点击状态
    redoBtnStatus: false, // 回退按钮可点击状态
    isStart: false,
    initialNum: 0,
  }

  // 是否是初始状态
  let isInitialStatus = true

  // 导出json时需要导出的自定义字段
  let _toJsonWithParams = []

  fabric.Object.NUM_FRACTION_DIGITS = 12 // 设置全局小数点精确度

  // 对象在鼠标按下/触摸启动时处于活动状态。如果将该值更改为“up”，对象将仅在鼠标打开/触摸结束后才会激活
  fabric.Object.prototype.activeOn = 'up'

  // 基础设置
  fabric.Object.prototype.set({
    borderColor: '#4391fc', // 边框颜色
    cornerColor: '#fff', // 激活状态角落图标的填充颜色
    cornerStrokeColor: 'red', // 激活状态角落图标边框颜色
    cornerSize: 80,
    touchCornerSize: 120,
    cornerStyle: 'circle',
    borderScaleFactor: 1.0 / zoom, // 边框缩放比例
    borderOpacityWhenMoving: 0.1,
    centeredScaling: false, // 角落放大缩小是否以图形中心为放大原点
    centeredRotation: true, // 旋转按钮旋转是否是左上角为圆心旋转
    transparentCorners: false, // 激活状态角落的图标是否透明
    rotatingPointOffset: 20, // 旋转距旋转体的距离
    originX: 'center',
    originY: 'center',
    lockUniScaling: true, // 只显示四角操作
    hasRotatingPoint: true, // 是否显示旋转按钮
  })

  // 添加按钮
  const rotateBtnImg = document.createElement('img')
  const circleBtnImg = document.createElement('img')
  const moveBtnImg = document.createElement('img')

  rotateBtnImg.src = require('../assets/editor/rotateIcon.svg')
  circleBtnImg.src = require('../assets/editor/edgeControl.svg')
  moveBtnImg.src = require('../assets/editor/moveControl.svg')

  const controlsConfig = {
    cursorStyle: 'pointer',
    cornerColor: '#fff',
  }
  // 重新渲染控制按钮
  const renderIcon = (image, initialAngle = 0) => {
    return function (ctx, left, top, styleOverride, fabricObject) {
      const size = this.cornerSize
      ctx.save()
      ctx.translate(left, top)
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle + initialAngle))
      ctx.drawImage(image, -size / 2, -size / 2, size, size)
      ctx.restore()
    }
  }
  fabric.Object.prototype.controls.mtr = new fabric.Control({
    x: 0,
    y: -0.5,
    offsetY: -30 / zoom,
    render: renderIcon(rotateBtnImg),
    actionHandler: fabric.controlsUtils.rotationWithSnapping,
    cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
    withConnection: false,
    ...controlsConfig,
    cornerSize: 40 / zoom,
    touchCornerSize: 50 / zoom,
  })
  fabric.Object.prototype.controls.mr = new fabric.Control({
    x: 0.5,
    y: 0,
    offsetX: 30 / zoom,
    offsetY: -1 / zoom,
    render: renderIcon(moveBtnImg),
    actionHandler: fabric.controlsUtils.dragHandler,
    // cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
    withConnection: false,
    ...controlsConfig,
    cornerSize: 40 / zoom,
    touchCornerSize: 50 / zoom,
  })

  fabric.Object.prototype.controls.br = new fabric.Control({
    x: 0.5,
    y: 0.5,
    offsetY: 1 / zoom,
    render: renderIcon(circleBtnImg),
    actionHandler: fabric.controlsUtils.scalingEqually,
    ...controlsConfig,
    cornerSize: 30 / zoom,
    touchCornerSize: 40 / zoom,
  })
  const configSize = {
    width: vm.modelWidth,
    height: vm.modelHeight,
  }
  // fabric 实例
  const fabricInstance = new fabric.Canvas(id, { ..._fabricConfig, ...configSize })

  // 初始化画布背景图
  fabricInstance.setBackgroundImage(vm.bgImage, fabricInstance.requestRenderAll.bind(fabricInstance), {
    width: fabricInstance.width,
    height: fabricInstance.height,
    // Needed to position backgroundImage at 0/0
    originX: 'left',
    originY: 'top',
    crossOrigin: 'anonymous',
  })

  // // 定义自定义类型 -icon
  // class Icon extends fabric.Image {
  //   // eslint-disable-next-line no-useless-constructor
  //   constructor(element, options) {
  //     super(element, options)
  //     // 这里可以添加自定义的初始化逻辑
  //   }

  //   customMethod() {
  //     // 添加自定义方法的逻辑
  //   }
  // }
  // // 注册自定义类
  // fabric.Object.prototype.objectCaching = false
  // fabric.Icon = Icon
  // fabric.Icon.fromObject = function (object, callback) {
  //   return new fabric.Icon(object, callback) // 修改这一行
  // }
  // 对 fabric，Canvas 扩展
  fabric.Canvas.prototype.setZoomByCenter = function (value) {
    this.zoomToPoint(new fabric.Point(fabricInstance.width / 2, fabricInstance.height / 2), value)
    return this
  }

  // 初始化配置项 -完成
  const _initConfig = function (config = {}) {
    Object.assign(_fabricConfig, config)
    fabricInstance.setOptions(_fabricConfig)
  }

  // 获取画布的活动对象 -完成
  const getActiveObj = () => fabricInstance.getActiveObject()

  // 获取画布的所有对象 -完成
  const getObjects = () => fabricInstance.getObjects()

  // 初始化画布 -完成
  const initCanvas = function () {
    if (isInitialStatus) return

    const json = _config.canvasStack[0]
    fabricInstance.clear()
    isInitialStatus = true
    _config.isStart = false
    _config.canvasStack = []
    _config.currentStateIndex = -1
    fabricInstance.loadFromJSON(json, () => {
      firstAddToState(true)
      updateCanvasStack()
    })
    updateInitialStatus(isInitialStatus)
  }

  // 创建图片 - 完成 type 1 初始化 2 新增
  const createImage = async function (item, type) {
    const img = await asyncLoadImg(item)
    // console.log('加载完成', img)
    item.left = item.width / 2 + item.left
    item.top = item.height / 2 + item.top

    const scaleWZoom = vm.modelWidth / item.width
    const scaleHZoom = vm.modelHeight / item.height

    item.maxScale = Math.max(scaleWZoom, scaleHZoom)

    let scale = zoom
    img.set(item)
    // 添加自定义属性
    img.toObject = (function (toObject) {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          id: item.id,
          name: item.name,
          type: item.type,
          flag: item.flag || '',
          maxScale: item.maxScale,
        })
      }
    })(img.toObject)

    fabricInstance.add(img)
    if (type === 2) {
      scale = item.maxScale > zoom ? zoom : item.maxScale / 2
      img.scaleX = scale
      img.scaleY = scale
      img.center()
      img.setControlsVisibility({
        bl: false,
        mb: false,
        ml: false,
        mt: false,
        tl: false,
        tr: false,
      })
      img.setCoords()
      setActiveSelect(img)
      updateCanvasStack()
    }
  }
  // 创建图片 - 完成 type 1 初始化 2 新增
  // const createSvg = async function (item, type) {
  //   fabric.Image.fromURL(
  //     item.src,
  //     img => {
  //       const icon = new fabric.Icon(img.getElement(), item)

  //       item.left = item.width / 2 + item.left
  //       item.top = item.height / 2 + item.top

  //       const scaleWZoom = vm.modelWidth / item.width
  //       const scaleHZoom = vm.modelHeight / item.height

  //       item.maxScale = Math.max(scaleWZoom, scaleHZoom)

  //       let scale = zoom
  //       item.crossOrigin = 'anonymous'
  //       icon.set(item)
  //       // 添加自定义属性
  //       icon.toObject = (function (toObject) {
  //         return function () {
  //           return fabric.util.object.extend(toObject.call(this), {
  //             id: item.id,
  //             name: item.name,
  //             type: item.type,
  //             maxScale: item.maxScale,
  //           })
  //         }
  //       })(icon.toObject)

  //       fabricInstance.add(icon)
  //       if (type === 2) {
  //         scale = item.maxScale > zoom ? zoom : item.maxScale / 2
  //         icon.scaleX = scale
  //         icon.scaleY = scale
  //         icon.center()
  //         icon.setControlsVisibility({
  //           bl: false,
  //           mb: false,
  //           ml: false,
  //           mt: false,
  //           tl: false,
  //           tr: false,
  //         })
  //         icon.setCoords()
  //         setActiveSelect(icon)
  //         updateCanvasStack()
  //       }
  //     },
  //     { crossOrigin: 'anonymous' }
  //   )
  // }

  // 处理 fromURL异步加载图片问题
  const asyncLoadFromURL = imgURL => {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        imgURL.src,
        img => {
          resolve(img)
        },
        { crossOrigin: 'anonymous' }
      )
    })
  }
  // 使用 new fabric.Image 解决 fabric.Image.fromURL 在回退时，url图片地址i转成base64图片的情况
  const asyncLoadImg = imgURL => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = 'anonymous'
      img.onload = function () {
        const fabricImg = new fabric.Image(img)
        resolve(fabricImg)
      }

      img.onerror = function (error) {
        reject(error)
      }

      img.src = imgURL.src
    })
  }

  // 创建文本 - 完成 type 1 初始化 2 新增
  const createItext = async (item, type) => {
    const config = type === 2 ? defaultFontConfig : item
    if (item) {
      // 初始化
      if (type === 1) {
        // 字体加载
        const fontFamily = vm.fontFamilyList.find(font => font.psName === item.fontFamily)
        if (fontFamily) {
          const font = new FontFaceObserver(item.fontFamily)
          try {
            await font.load(null, 100000)
          } catch (error) {
            console.log('字体加载失败')
          }
        }
        config.left = item.width / 2 + item.left
        config.top = item.height / 2 + item.top
        config.fill = item.color
        config.fontFamily = item.fontFamily
        const text = new fabric.Text(item, config)
        // 添加自定义属性
        text.toObject = (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              id: item.id,
              name: item.name,
              type: item.type,
            })
          }
        })(text.toObject)

        fabricInstance.add(text)
      } else {
        // 修改文字
        fabricInstance.getActiveObject().set('text', item)
        fabricInstance.requestRenderAll()
      }
    } else {
      // 新增
      const text = new fabric.Text(config.text, config)

      // 添加自定义属性
      text.toObject = (function (toObject) {
        return function () {
          return fabric.util.object.extend(toObject.call(this), {
            id: config.id,
            name: config.name,
          })
        }
      })(text.toObject)

      fabricInstance.add(text)
      text.center()
      text.setControlsVisibility({
        bl: false,
        mb: false,
        ml: false,
        mt: false,
        tl: false,
        tr: false,
      })
      fabricInstance.setActiveObject(text)
      updateCanvasStack()
    }
  }

  // 修改字体属性
  const changeText = (key, value) => {
    fabricInstance.getActiveObject() && fabricInstance.getActiveObject().set(key, value)
    fabricInstance.requestRenderAll()
    updateCanvasStack()
  }

  // 记录每一步操作，用户撤销和回退操作
  const updateCanvasStack = function () {
    if (_config.undoStatus === false && _config.redoStatus === false) {
      const jsonData = fabricInstance.toJSON(['id', 'name', 'type', 'src', 'left', 'top'])
      const canvasAsJson = JSON.stringify(jsonData)

      if (_config.currentStateIndex < _config.canvasStack.length - 1) {
        const indexToBeInserted = _config.currentStateIndex + 1
        _config.canvasStack[indexToBeInserted] = canvasAsJson
        const numberOfElementsToRetain = indexToBeInserted + 1
        _config.canvasStack = _config.canvasStack.splice(0, numberOfElementsToRetain)
      } else {
        _config.canvasStack.push(canvasAsJson)
      }
      _config.currentStateIndex = _config.canvasStack.length - 1
      if (_config.currentStateIndex === _config.canvasStack.length - 1 && _config.currentStateIndex !== 0) {
        _config.undoBtnStatus = true
      }
    }
  }

  //更新撤销的操作状态
  const updateUndoBtnStatus = async function () {
    _config.undoStatus = true
    await fabricInstance.loadFromJSON(_config.canvasStack[_config.currentStateIndex - 1])
    fabricInstance.requestRenderAll()

    _config.undoStatus = false
    _config.currentStateIndex -= 1
    if (_config.currentStateIndex !== 0 && _config.currentStateIndex !== _config.canvasStack.length - 1) {
      _config.undoBtnStatus = true
      _config.redoBtnStatus = true
    } else {
      _config.undoBtnStatus = false
    }

    _config.undoFinishedStatus = 1
  }

  // 更新回退的操作状态
  const updateRedoBtnStatus = async function () {
    _config.redoStatus = true

    await fabricInstance.loadFromJSON(_config.canvasStack[_config.currentStateIndex + 1])
    fabricInstance.requestRenderAll()

    _config.redoStatus = false
    _config.currentStateIndex += 1

    _config.redoFinishedStatus = 1
    if (_config.currentStateIndex !== 0 && _config.currentStateIndex === _config.canvasStack.length - 1) {
      _config.redoBtnStatus = false
      // _config.undoBtnStatus = false
    } else {
      _config.undoBtnStatus = true
    }
  }
  // 撤销
  const undo = async function () {
    if (_config.undoFinishedStatus === 1) {
      if (_config.currentStateIndex === 0) {
        _config.undoBtnStatus = false
      } else {
        if (_config.canvasStack.length >= 1) {
          _config.undoFinishedStatus = 0
          if (_config.currentStateIndex !== 0) {
            await updateUndoBtnStatus()
          } else if (_config.currentStateIndex === 0) {
            _config.undoFinishedStatus = 1
            _config.undoBtnStatus = false
            _config.redoBtnStatus = false
            _config.currentStateIndex -= 1
          }
        }
      }
    }
  }

  // 恢复
  const redo = async function () {
    if (_config.redoFinishedStatus === 1) {
      if (_config.currentStateIndex === _config.canvasStack.length - 1 && _config.currentStateIndex !== -1) {
        _config.redoBtnStatus = true
      } else {
        if (_config.canvasStack.length > _config.currentStateIndex && _config.canvasStack.length !== 0) {
          _config.redoFinishedStatus = 0
          _config.redoStatus = true
          await updateRedoBtnStatus()
        }
      }
    }
  }

  // 调整层级关系 -已完成
  const updateLevel = function (last, order) {
    fabricInstance.moveTo(last, order)
    updateCanvasStack()
  }

  // 上层
  const toLastLayer = function () {
    fabricInstance.bringForward(getActiveObj())
    updateCanvasStack()
  }

  // 下层
  const toNextLayer = function () {
    fabricInstance.sendBackwards(getActiveObj())
    updateCanvasStack()
  }

  // 置底
  const toBottomLayer = function () {
    fabricInstance.sendToBack(getActiveObj())
    updateCanvasStack()
  }

  // 置顶
  const toTopLayer = function () {
    fabricInstance.bringToFront(getActiveObj())
    updateCanvasStack()
  }

  // 更新是否是初始状态
  const updateInitialStatus = function (status) {
    isInitialStatus = status
  }

  // 设置导出json时，需要导出自定义字段
  const setToJsonWithParams = function (list) {
    _toJsonWithParams = _toJsonWithParams.concat(list)
  }

  // 删除元素 - 完成
  const delItem = function () {
    fabricInstance.remove(fabricInstance.getActiveObject())
    updateCanvasStack()
  }

  // 开始记录操作，用于撤销回退-完成
  const firstAddToState = function (isStart = false) {
    _config.isStart = isStart
  }

  // 设置选中项-完成
  const setActiveSelect = function (obj) {
    fabricInstance.setActiveObject(obj)
    // fabricInstance.requestRenderAll()
  }

  // 获取透明度 - 完成
  const getOpacity = () => {
    return fabricInstance.getActiveObject().get('opacity') * 100
  }

  // 设置透明度 - 完成
  const setOpacity = function (value) {
    fabricInstance.getActiveObject().set('opacity', value / 100)
    fabricInstance.requestRenderAll()
    updateCanvasStack()
  }

  // 获取图片亮度 - 完成
  const getBrightness = () => {
    return fabricInstance.getActiveObject().filters[0]?.brightness * 100 || 0
  }

  // 设置图片亮度 - 完成
  const setBrightness = function (value) {
    const sourceImg = fabricInstance.getActiveObject()
    const { length } = sourceImg.filters
    let itemFilter = null
    let item, i

    for (i = 0; i < length; i += 1) {
      item = sourceImg.filters[i]
      if (item.type === 'Brightness') {
        itemFilter = item
        break
      }
    }
    if (itemFilter) {
      itemFilter['brightness'] = value / 100
    } else {
      const imgFilter = _createFilter(fabric, fabricInstance, sourceImg, 'Brightness')
      imgFilter['brightness'] = value / 100
    }
    sourceImg.applyFilters()
    fabricInstance.requestRenderAll()
    updateCanvasStack()
  }

  // 复制图层 -完成
  const copyLayer = function () {
    // 间距设置
    const grid = 10
    fabricInstance.getActiveObject()?.clone(cloned => {
      if (cloned.left === undefined || cloned.top === undefined) return
      fabricInstance.discardActiveObject()
      // 设置位置信息
      cloned.set({
        left: cloned.left + grid,
        top: cloned.top + grid,
        evented: true,
        id: createUniqueString(),
      })
      cloned.setControlsVisibility({
        bl: false,
        mb: false,
        ml: false,
        mt: false,
        tl: false,
        tr: false,
      })
      fabricInstance.add(cloned)
      setActiveSelect(cloned)
      updateCanvasStack()
    })
  }

  // 导出json数据
  const toJSon = function () {
    return fabricInstance.toJSON(_toJsonWithParams)
  }
  // 预览图
  const toPng = function () {
    try {
      const option = {
        name: 'New Image',
        format: 'png',
        quality: 1,
        width: fabricInstance.width,
        height: fabricInstance.height,
      }
      // 使用base64 网络图片需要解决跨域问题
      const url = fabricInstance.toDataURL(option)
      return url
    } catch (error) {
      console.log('预览失败', error)
    }
  }

  return {
    _config,
    fabric,
    toJSon,
    toPng,
    initFabricConfig: _initConfig,
    fabricInstance,
    getActiveObj,
    getObjects,
    updateLevel,
    toNextLayer,
    toBottomLayer,
    toLastLayer,
    toTopLayer,
    getOpacity,
    setOpacity,
    getBrightness,
    setBrightness,
    initCanvas,
    setToJsonWithParams,
    firstAddToState,
    createImage,
    // createSvg,
    createItext,
    changeText,
    updateCanvasStack,
    setActiveSelect,
    copyLayer,
    delItem,
    undo,
    redo,
  }
}
