<!--
 * @Description: 图片面板
 * @Author: 王立俊
 * @Date: 2023-06-14 11:46:58
 * @LastEditTime: 2023-09-27 16:51:09
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
-->
<template>
  <div class="editor-panel editor-panel-img">
    <!-- 图片上传加载中 -->
    <van-overlay :show="showLoading" @click="showLoading = false">
      <div class="uploading-loading" @click.stop>
        <van-loading vertical color="#1989fa">{{ loadingTitle }}</van-loading>
      </div>
    </van-overlay>
    <div class="editor-transparent-box">
      <div style="display: flex; align-items: center; padding-bottom: 10px">
        <div class="name">左</div>
        <van-slider
          v-model="left"
          :max="maxLeft"
          :min="minLeft"
          class="slider"
          bar-height="4px"
          active-color="#4391FC"
          @input="onChangeLeft"
        />
        <div style="color: #4391fc">{{ left }}</div>
      </div>
      <div style="display: flex; align-items: center; padding-bottom: 10px">
        <div class="name">上</div>
        <van-slider
          v-model="top"
          :max="maxTop"
          :min="minTop"
          class="slider"
          bar-height="4px"
          active-color="#4391FC"
          @input="onChangeTop"
        />
        <div style="color: #4391fc">{{ top }}</div>
      </div>
      <div style="display: flex; align-items: center">
        <div class="name">缩放</div>
        <van-slider
          v-model="scale"
          :max="maxScale"
          :min="minScale"
          class="slider"
          bar-height="4px"
          active-color="#4391FC"
          @input="onChangeScale"
        />
        <div style="color: #4391fc">{{ scale }}</div>
      </div>
    </div>
    <div class="editor-direction">
      <div class="name">对齐</div>
      <div class="editor-direction-item">
        <span class="item" @click="handleDirection('rightTop')">右上</span>
        <span class="item" @click="handleDirection('leftTop')">左上</span>
        <span class="item" @click="handleDirection('center')">居中</span>
        <span class="item" @click="handleDirection('leftBottom')">左下</span>
        <span class="item" @click="handleDirection('rightBottom')">右下</span>
      </div>
    </div>

    <div class="editor-btn-group">
      <span class="editor-panel-btn" @click="cutImage">
        <i class="iconfont icon-cropping" />
        <span class="desc">裁剪</span>
      </span>
      <span class="editor-panel-btn">
        <input ref="input" type="file" accept="image/*" class="select-file" @change="addImage" />
        <i class="iconfont icon-replace" />
        <span class="desc">替换</span>
      </span>
    </div>
  </div>
</template>

<script>
import { insertImgFile } from '@/utils/util'
import { uploadFile } from '@/api/base'
export default {
  name: 'ImgPanel',
  data() {
    return {
      showLoading: false,
      loadingTitle: '上传中...',
      inputDom: '',
      url: '',
      left: 0,
      top: 0,
      maxLeft: 100,
      minLeft: 0,
      maxTop: 100,
      minTop: 0,
      width: 0,
      height: 0,
      scale: 0,
      maxScale: 250,
      minScale: 0,
    }
  },
  methods: {
    show(activeObj) {
      // 计算出 缩放后的宽高
      this.width = activeObj.width * (activeObj.scaleX || 1)
      this.height = activeObj.height * (activeObj.scaleY || 1)

      // 因以中心点为原点，故需计算出 所选对象的中心点距离(即宽高的一半)
      const halfWidth = (activeObj.width * (activeObj.scaleX || 1)) / 2
      const halfHeight = (activeObj.height * (activeObj.scaleY || 1)) / 2

      // TODO: 计算缩放比
      this.scale = Math.ceil(activeObj.scaleX * 100) || 0
      if (activeObj.maxScale) {
        this.maxScale = activeObj.maxScale * 100
      } else {
        if (Math.ceil(activeObj.scaleX * 100) > this.maxScale) {
          this.maxScale = Math.ceil(activeObj.scaleX * 100)
        }
      }

      // 计算左边最大值
      this.maxLeft = Math.ceil(activeObj.canvas.width - this.width)
      this.maxTop = Math.ceil(activeObj.canvas.height - this.height)

      // 左滑动出画布后，固定在画布中
      const right =
        activeObj.left + halfWidth >= activeObj.canvas.width ? activeObj.canvas.width - halfWidth : activeObj.left
      const top =
        activeObj.top + halfHeight > activeObj.canvas.height ? activeObj.canvas.height - halfHeight : activeObj.top
      const flgLeft = right < halfWidth ? 0 : right - halfWidth
      const flgTop = top < halfHeight ? 0 : top - halfHeight

      this.left = Math.ceil(flgLeft)
      this.top = Math.ceil(flgTop)

      if (this.left > this.maxLeft) {
        this.maxLeft = this.left
      }
      if (this.top > this.maxTop) {
        this.maxTop = this.top
      }
    },
    onChangeLeft(val) {
      val = Math.ceil(val + this.width / 2)
      this.$emit('onLeft', val)
    },
    onChangeTop(val) {
      val = Math.ceil(val + this.height / 2)
      this.$emit('onTop', val)
    },
    onChangeScale(val) {
      this.$emit('onScale', val)
    },
    // 替换图片
    async addImage(e) {
      this.inputDom = e.target
      const files = this.inputDom.files
      const file = files[0]

      if (!/image\/\w+/.test(file.type)) {
        return this.$toast('请上传正确的图片格式')
      }
      // 图片尺寸超过2mb
      if (file.size > 2 * 1024 * 1024) {
        this.photoCompress(
          file,
          {
            // 调用压缩图片方法
            quality: 0.2,
          },
          base64Codes => {
            const bl = this.base64UrlToBlob(base64Codes)
            this.loadingTitle = '压缩上传中...'
            this.uploadImg(bl) // 请求图片上传接口
          }
        )
      } else {
        this.loadingTitle = '上传中...'
        this.uploadImg(file)
      }
    },
    // 压缩图片
    photoCompress(file, obj, callback) {
      const that = this
      const ready = new FileReader()
      /* 开始读取指定File对象中的内容. 读取操作完成时,返回一个URL格式的字符串. */
      ready.readAsDataURL(file)
      ready.onload = function () {
        const re = this.result
        that.canvasDataURL(re, obj, callback) // 开始压缩
      }
    },

    /*利用canvas数据化图片进行压缩
     * 图片转base64
     */
    canvasDataURL(path, obj, callback) {
      const img = new Image()
      img.src = path
      img.onload = function () {
        const that = this // 指到img
        // 默认按比例压缩
        let w = that.width,
          h = that.height
        const scale = w / h
        w = obj.width || w
        h = obj.height || w / scale
        let quality = 0.2 // 默认图片质量为0.7
        // 生成canvas
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        // 创建属性节点
        const anw = document.createAttribute('width')
        anw.nodeValue = w
        const anh = document.createAttribute('height')
        anh.nodeValue = h
        canvas.setAttributeNode(anw)
        canvas.setAttributeNode(anh)
        ctx.drawImage(that, 0, 0, w, h)
        // 图像质量
        if (obj.quality && obj.quality >= 1 && obj.quality < 0) {
          quality = obj.quality
        }
        // quality值越小，所绘制出的图像越模糊
        const base64 = canvas.toDataURL('image/jpeg', quality)
        // 回调函数返回base64的值
        callback(base64)
      }
    },
    // 上传图片
    async uploadImg(file) {
      try {
        this.showLoading = true
        const formData = new FormData()
        formData.append('file', file)
        formData.append('uploadType', 0)
        // 上传
        const {
          data: { filePath },
        } = await uploadFile(formData)
        const url = 'https://bimei.oss-cn-hangzhou.aliyuncs.com' + filePath
        // const url = 'https://file.td.lcqimeng.com' + filePath
        const _this = this
        const imgEl = await insertImgFile(url)
        this.inputDom.value = ''
        await _this.$emit('replaceImg', imgEl)
        this.showLoading = false
      } catch (error) {
        this.loadingTitle = '上传失败...'
        this.inputDom.value = ''
        setTimeout(() => {
          this.showLoading = false
        }, 800)
      }
    },

    /* base64 转 Blob 格式 和file格式*/
    base64UrlToBlob(urlData) {
      const arr = urlData.split(','),
        mime = arr[0].match(/:(.*?);/)[1], // 去掉url的头，并转化为byte
        bstr = atob(arr[1]) // 处理异常,将ascii码小于0的转换为大于0
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      // 转blob
      // return new Blob([u8arr], {type: mime})
      const filename = Date.parse(new Date()) + '.jpg'
      // 转file
      return new File([u8arr], filename, { type: mime })
    },

    // 裁剪图片
    cutImage() {
      this.$emit('showPanel', 'show-cut-panel')
    },
    // 对齐位置
    handleDirection(type) {
      this.$emit('onDirection', type)
    },
  },
}
</script>

<style lang="scss" scoped>
.editor-panel-img {
  background: #fff;
  .editor-direction {
    padding: 8px 0 8px 15px;
    display: flex;
    color: #9ba2ac;
    font-size: 14px;
    border-bottom: 1px solid #e8e8e8;
    .editor-direction-item {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      .item {
        padding: 2px 8px;
        margin-right: 10px;
        border-radius: 3px;
        border: 1px solid #cdd5e1;
        background: #fff;
        font-size: 12px;
      }
      .item:active {
        color: #4391fc;
        border: 1px solid #4391fc;
      }
    }
  }
}

i {
  font-size: 16px;
  margin: 10px;
  color: #fff;
}

.editor-btn-group {
  display: flex;
  justify-content: space-around;
  text-align: center;
  background-color: #ffffff;
  padding: 3px 14%;
  .editor-panel-btn {
    position: relative;
    display: inline-block;
    overflow: hidden;

    i {
      font-size: 20px;
      margin: 3px;
      color: #000;
    }
    display: flex;
    flex-direction: column;
    position: relative;
    .select-file {
      width: 20px;
      z-index: 1;
      opacity: 0;
      position: absolute;
      cursor: pointer;
    }
    .desc {
      color: #9ba2ac;
      font-size: 12px;
      display: block;
      padding-top: 3px;
    }
  }
}
.name {
  width: 25px;
  min-width: 25px;
  font-size: 12px;
}
.editor-transparent-box {
  padding: 8px 15px;
  color: #9ba2ac;
  font-size: 14px;

  .first {
    padding-bottom: 8px;
  }
  .slider {
    width: 70%;
    margin: 0 20px;
    ::v-deep .van-slider__button {
      width: 15px !important;
      height: 15px !important;
    }
  }
}
</style>
