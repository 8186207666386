<!--
 * @Description: 图层顺序
 * @Author: 王立俊
 * @Date: 2023-06-14 11:48:10
 * @LastEditTime: 2023-09-01 16:43:17
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
-->
<template>
  <div class="editor-panel-level">
    <div class="editor-level-box">
      <span v-for="(item, index) in layerLevel" :key="index" class="desc" @click="onSelectLevel(item.value)">
        {{ item.name }}
      </span>
    </div>
    <div class="default-name">
      <div class="name-box">
        <div v-for="(item, index) in layers" :key="index" class="name" @click="onSelectObj(item, index)">
          {{ item.name || '未定义' }}
        </div>
      </div>
    </div>
    <div class="editor-bottom-btn">
      <i class="iconfont icon-close" @click="handleClose" />
      <div class="title">图层顺序</div>
      <i class="iconfont icon-success" @click="handleSuccess" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransparentPanel',
  props: {
    layers: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      activeObj: {},
      layerLevel: [
        { name: '置底', value: 0 },
        { name: '下移', value: 1 },
        { name: '上移', value: 2 },
        { name: '置顶', value: 3 }
      ],
      active: null
    }
  },
  methods: {
    // 展示面板
    show(obj) {
      this.active = null
      this.activeObj = obj
    },

    //选中层级顺序
    onSelectLevel(value) {
      this.active = value
      this.$emit('onLevel', value)
    },
    // 选择层级
    onSelectObj(item, index) {
      this.lastIndex = index
      this.$emit('onSelectObj', item)
    },
    // 关闭
    handleClose() {
      this.$emit('close')
    },
    // 成功
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>

<style lang="scss" scoped>
i {
  font-size: 20px;
}
.editor-panel-level {
  background: #fff;
  .editor-level-box {
    padding: 4px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .desc {
      padding: 4px 20px;
      border-radius: 5px;
      border: 1px solid #cdd5e1;
      background: #fff;
      color: #9ba2ac;
      text-align: center;
      font-size: 12px;
    }
    .desc:active {
      border: 1px solid #4391fc;
      background: #4391fc;
      color: #fff;
    }
  }
  // 层级关系
  .default-name {
    position: relative;
    padding: 9px 15px;
    background-color: #ffffff;
    border-bottom: 1px solid #edeff3;
    display: flex; /* 将子元素横向排列 */
    align-items: center;
    overflow: hidden;

    .name-box {
      position: relative;
      display: flex; /* 将子元素横向排列 */
      white-space: nowrap; /* 避免子元素在父元素内换行 */
      overflow-x: scroll;
      ::-webkit-scrollbar {
        width: 0 !important;
      }
      ::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
      .name {
        margin-right: 6px;
        padding: 4px 6px;
        border-radius: 5px;
        border: 1px solid #cdd5e1;
        background: #fff;
        color: #9ba2ac;
        text-align: center;
        font-size: 12px;
      }
      .name:active {
        color: #4391fc;
        border: 1px solid #4391fc;
      }
    }
  }
}
</style>
