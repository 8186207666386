<!--
 * @Description: 素材面板
 * @Author: 王立俊
 * @Date: 2023-06-14 11:46:58
 * @LastEditTime: 2023-09-19 10:56:11
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
-->
<template>
  <div class="editor-panel editor-panel-img">
    <div class="editor-transparent-box">
      <div style="display: flex; align-items: center; padding-bottom: 10px">
        <div class="name">左</div>
        <van-slider
          v-model="left"
          :max="maxLeft"
          :min="minLeft"
          class="slider"
          bar-height="4px"
          active-color="#4391FC"
          @input="onChangeLeft"
        />
        <div style="color: #4391fc">{{ left }}</div>
      </div>
      <div style="display: flex; align-items: center; padding-bottom: 10px">
        <div class="name">上</div>
        <van-slider
          v-model="top"
          :max="maxTop"
          :min="minTop"
          class="slider"
          bar-height="4px"
          active-color="#4391FC"
          @input="onChangeTop"
        />
        <div style="color: #4391fc">{{ top }}</div>
      </div>
      <div style="display: flex; align-items: center">
        <div class="name">缩放</div>
        <van-slider
          v-model="scale"
          :max="maxScale"
          :min="minScale"
          class="slider"
          bar-height="4px"
          active-color="#4391FC"
          @input="onChangeScale"
        />
        <div style="color: #4391fc">{{ scale }}</div>
      </div>
    </div>
    <div class="editor-btn-group">
      <span class="editor-panel-btn" @click="addSvg">
        <i class="iconfont icon-replace" />
        <span class="desc">替换</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconPanel',
  data() {
    return {
      url: '',
      left: 0,
      top: 0,
      maxLeft: 100,
      minLeft: 0,
      maxTop: 100,
      minTop: 0,
      width: 0,
      height: 0,
      scale: 0,
      maxScale: 250,
      minScale: 0,
    }
  },
  methods: {
    show(activeObj) {
      try {
        // 计算出 缩放后的宽高
        this.width = activeObj.width * (activeObj.scaleX || 1)
        this.height = activeObj.height * (activeObj.scaleY || 1)

        // 因以中心点为原点，故需计算出 所选对象的中心点距离(即宽高的一半)
        const halfWidth = (activeObj.width * (activeObj.scaleX || 1)) / 2
        const halfHeight = (activeObj.height * (activeObj.scaleY || 1)) / 2

        // TODO: 计算缩放比
        this.scale = Math.ceil(activeObj.scaleX * 100) || 0
        if (activeObj.maxScale) {
          this.maxScale = activeObj.maxScale * 100
        } else {
          if (Math.ceil(activeObj.scaleX * 100) > this.maxScale) {
            this.maxScale = Math.ceil(activeObj.scaleX * 100)
          }
        }

        // 计算左边最大值
        this.maxLeft = Math.ceil(activeObj.canvas.width - this.width)
        this.maxTop = Math.ceil(activeObj.canvas.height - this.height)

        // 左滑动出画布后，固定在画布中
        const right =
          activeObj.left + halfWidth >= activeObj.canvas.width ? activeObj.canvas.width - halfWidth : activeObj.left
        const top =
          activeObj.top + halfHeight > activeObj.canvas.height ? activeObj.canvas.height - halfHeight : activeObj.top
        const flgLeft = right < halfWidth ? 0 : right - halfWidth
        const flgTop = top < halfHeight ? 0 : top - halfHeight

        this.left = Math.ceil(flgLeft)
        this.top = Math.ceil(flgTop)

        if (this.left > this.maxLeft) {
          this.maxLeft = this.left
        }
        if (this.top > this.maxTop) {
          this.maxTop = this.top
        }
      } catch (error) {
        console.log(error)
      }
    },
    onChangeLeft(val) {
      val = Math.ceil(val + this.width / 2)
      this.$emit('onLeft', val)
    },
    onChangeTop(val) {
      val = Math.ceil(val + this.height / 2)
      this.$emit('onTop', val)
    },
    onChangeScale(val) {
      this.$emit('onScale', val)
    },
    // 替换素材
    addSvg() {
      this.$emit('showPanel', 'show-icon-style-panel')
    },

    // 修改素材颜色
    onColor() {
      this.$emit('showPanel', 'show-text-colors-panel')
    },
  },
}
</script>

<style lang="scss" scoped>
.editor-panel-img {
  background: #fff;
  .editor-btn-group {
    display: flex;
    justify-content: space-around;
    text-align: center;
    background-color: #ffffff;
    padding: 3px 14%;
    .editor-panel-btn {
      position: relative;
      display: inline-block;
      overflow: hidden;

      i {
        font-size: 20px;
        margin: 3px;
        color: #000;
      }
      display: flex;
      flex-direction: column;
      position: relative;
      .select-file {
        width: 20px;
        z-index: 1;
        opacity: 0;
        position: absolute;
        cursor: pointer;
      }
      .desc {
        color: #9ba2ac;
        font-size: 12px;
        display: block;
        padding-top: 3px;
      }
    }
  }
}

i {
  font-size: 16px;
  margin: 10px;
  color: #fff;
}
.editor-transparent-box {
  padding: 8px 15px;
  color: #9ba2ac;
  font-size: 14px;
  .name {
    width: 25px;
    min-width: 25px;
    font-size: 12px;
  }
  .first {
    padding-bottom: 8px;
  }
  .slider {
    width: 70%;
    margin: 0 20px;
    ::v-deep .van-slider__button {
      width: 15px !important;
      height: 15px !important;
    }
  }
}
</style>
