<!--
 * @Description:
 * @Author: 王立俊
 * @Date: 2023-08-16 11:03:00
 * @LastEditTime: 2023-09-21 14:56:27
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
-->
<template>
  <div class="uploader__upload">
    <img id="img" :src="imgUrl" alt="" class="image__img" />
  </div>
</template>
<script>
import Cropper from 'cropperjs'
export default {
  props: {
    // 定义的宽高比
    widthRate: {
      type: Number,
      default: 1,
    },
    // 定义的宽高比
    heightRate: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      imgUrl: '',
      resultObj: null,
    }
  },

  methods: {
    // 获取组件传值
    show(cutImg) {
      console.log('cutImg', cutImg)
      this.imgUrl = cutImg.src
      // 显示裁剪框
      this.clip({
        resultObj: cutImg,
      })
    },

    //初始化方法
    initialize(opt) {
      const self = this
      self.options = opt
      //创建dom
      self.createElement()
      self.resultObj = opt.resultObj
      //初始化裁剪对象
      self.cropper = new Cropper(self.preview, {
        autoCropArea: opt.autoCropArea || 0.8,
        guides: true, // 是否在剪裁框上显示虚线
        cropBoxResizable: true, //是否通过拖动来调整剪裁框的大小
        cropBoxMovable: true, //是否通过拖拽来移动剪裁框。
        dragCrop: false,
        dragMode: 'move', //‘crop’: 可以产生一个新的裁剪框3 ‘move’: 只可以移动3 ‘none’: 什么也不处理
        center: false, // 是否显示裁剪框中间的+
        zoomable: true, //是否允许放大图像。
        zoomOnTouch: true, //是否可以通过拖动触摸来放大图像。
        scalable: true, // 是否允许缩放图片
        // minCropBoxHeight: 750,
        // minCropBoxWidth: 750,
        fixed: false, // 是否固定裁剪框
        background: true, // 容器是否显示网格背景
        checkOrientation: true,
        checkCrossOrigin: true,
        zoomOnWheel: false, // 是否允许鼠标滚轴缩放图片
        toggleDragModeOnDblclick: false,
        viewMode: 2,
        ready: function () {
          if (opt.aspectRatio === 'Free') {
            const cropBox = self.cropper.cropBox
            cropBox.querySelector('span.cropper-view-box').style.outline = 'none'
            self.cropper.disable()
          }
        },
      })
    },
    //创建一些必要的DOM，用于图片裁剪
    createElement() {
      //初始化图片为空对象
      this.preview = null

      let str =
        '<div><img id="clip_image" src="originUrl"></div><span id="crop-btn"><i class="iconfont icon-close" id="cancel_clip"></i><i class="iconfont icon-success" id="clip_button"></i></span>'
      str += '<div class="crop_loading"><div class="crop_content"><div class="crop_text">修剪中...</div></div></div>'
      str += '<div class="crop_success"><div class="crop_success_text">上传成功</div></div></div>'

      const body = document.getElementsByTagName('body')[0]
      this.region = document.createElement('div')
      this.region.id = 'clip_container'
      this.region.className = 'container'
      this.region.innerHTML = str
      //添加创建好的DOM元素
      body.appendChild(this.region)
      this.preview = document.getElementById('clip_image')

      //绑定一些方法
      this.initFunction()
    },
    //初始化一些函数绑定
    initFunction() {
      const self = this
      this.clickBtn = document.getElementById('clip_button')
      // this.revolveBtn = document.getElementById('revolve')
      this.cancelBtn = document.getElementById('cancel_clip')
      //确定事件
      this.addEvent(this.clickBtn, 'click', function () {
        self.crop()
      })
      //旋转事件
      // this.addEvent(this.revolveBtn, 'click', function () {
      //   self.revolve()
      // })
      //取消事件
      this.addEvent(this.cancelBtn, 'click', function () {
        self.destroyed()
      })
    },

    //开启裁剪框
    clip(opt) {
      //调用初始化方法
      this.initialize(opt)
      this.originUrl = opt.resultObj.src
      //每次替换图片要重新得到新的url
      if (this.cropper) {
        this.cropper.replace(this.originUrl)
      }
    },

    //点击确定进行裁剪
    crop() {
      const self = this
      let croppedCanvas
      let roundedCanvas

      // Crop
      document.querySelector('.crop_loading').style.display = 'block'

      setTimeout(function () {
        croppedCanvas = self.cropper.getCroppedCanvas()
        // Round
        roundedCanvas = self.getRoundedCanvas(croppedCanvas)

        const imgData = roundedCanvas.toDataURL()
        self.resultObj.src = imgData

        self.$emit('after-upload', self.resultObj.src) // 调用后台接口
        self.destroyed()
      }, 20)
    },

    //获取裁剪图片资源
    getRoundedCanvas(sourceCanvas) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const width = sourceCanvas.width
      const height = sourceCanvas.height

      canvas.width = width
      canvas.height = height

      context.imageSmoothingEnabled = true
      context.drawImage(sourceCanvas, 0, 0, width, height)
      context.globalCompositeOperation = 'destination-in'
      context.beginPath()
      context.rect(0, 0, width, height)
      context.fill()

      return canvas
    },

    // 旋转图片
    revolve() {
      const canvas = document.createElement('canvas')
      this.rotateImg(this.resultObj, 'right', canvas)
    },

    //销毁原来的对象
    destroyed() {
      const self = this
      //移除事件
      this.removeEvent(this.clickBtn, 'click', null)
      // this.removeEvent(this.revolveBtn, 'click', null)
      this.removeEvent(this.cancelBtn, 'click', null)
      //移除裁剪框
      this.region.parentNode.removeChild(this.region)

      //销毁裁剪对象
      this.cropper.destroy()
      this.cropper = null
      this.$emit('close')
    },

    //图片旋转
    rotateImg(img, direction = 'right', canvas) {
      //最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0
      const max_step = 3
      if (img == null) return
      //img的高度和宽度不能在img元素隐藏后获取，否则会出错
      const height = img.height
      const width = img.width
      let step = 2
      if (step == null) {
        step = min_step
      }
      if (direction === 'right') {
        step++
        //旋转到原位置，即超过最大值
        step > max_step && (step = min_step)
      } else {
        step--
        step < min_step && (step = max_step)
      }
      //旋转角度以弧度值为参数
      const degree = (step * 90 * Math.PI) / 180
      const ctx = canvas.getContext('2d')
      switch (step) {
        case 0:
          canvas.width = width
          canvas.height = height
          ctx.drawImage(img.src, 0, 0)
          break
        case 1:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img.src, 0, -height)
          break
        case 2:
          canvas.width = width
          canvas.height = height
          ctx.rotate(degree)
          ctx.drawImage(img.src, -width, -height)
          break
        case 3:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img.src, -width, 0)
          break
      }
    },

    //添加事件
    addEvent(obj, type, fn) {
      if (obj.addEventListener) {
        obj.addEventListener(type, fn, false)
      } else {
        obj.attachEvent('on' + type, fn)
      }
    },

    //移除事件
    removeEvent(obj, type, fn) {
      if (obj.removeEventListener) {
        obj.removeEventListener(type, fn, false)
      } else {
        obj.detachEvent('on' + type, fn)
      }
    },
  },
}
</script>
<style scoped>
.uploader__upload {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
}

.image__img {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}
#zyg-upload-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#zyg-delete-icon {
  display: none;
  position: absolute;
  top: -8px;
  right: -8px;
  color: #969799;
  font-size: 18px;
  background-color: #fff;
  border-radius: 100%;
  z-index: 100;
}
.van-uploader__mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: #fff;
  background-color: rgba(50, 50, 51, 0.88);
  z-index: 1000;
}
</style>
<style>
input[type='file'] {
  outline: none;
  /*margin-top: 20px;*/
}

#crop-btn {
  width: calc(100% - 40px);
  padding: 15px 20px;
  position: absolute;
  bottom: 0;
  background: #1c1f2d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#clip_button {
  color: #4391fc;
}

#cancel_clip {
  color: #ff487d;
}
#revolve {
  color: #cdd5e1;
  font-size: 12px;
}
#clip_container.container {
  z-index: 99999;
  position: fixed;
  padding-top: 60px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);
}

#clip_container.container > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#clip_image {
  max-width: 100%;
}

.cropper-container {
  font-size: 0;
  line-height: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
}

.crop_loading,
.crop_success {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.crop_loading .crop_content {
  position: fixed;
  top: 50%;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: content-box;
  width: 88px;
  max-width: 70%;
  min-height: 88px;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.crop_loading .crop_content img {
  margin-top: 15px;
  margin-bottom: 10px;
}

.crop_success .crop_success_text {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  background: #000;
  opacity: 0.9;
  width: 120px;
  height: 30px;
  color: #fff;
  line-height: 30px;
  font-size: 16px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cropper-container img {
  /* Avoid margin top issue (Occur only when margin-top <= -height) */
  display: block;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  width: 100%;
  height: 100%;
  image-orientation: 0deg;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cropper-wrap-box {
  overflow: hidden;
}

.cropper-drag-box {
  opacity: 0;
  background-color: #fff;
}

.cropper-modal {
  opacity: 0.5;
  background-color: #000;
}

.cropper-view-box {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, 0.75);
}

.cropper-dashed {
  position: absolute;
  display: block;
  opacity: 0.5;
  border: 0 dashed #eee;
}

.cropper-dashed.dashed-h {
  top: 33.33333%;
  left: 0;
  width: 100%;
  height: 33.33333%;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.cropper-dashed.dashed-v {
  top: 0;
  left: 33.33333%;
  width: 33.33333%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
}

.cropper-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  opacity: 0.75;
}

.cropper-center:before,
.cropper-center:after {
  position: absolute;
  display: block;
  content: ' ';
  background-color: #eee;
}

.cropper-center:before {
  top: 0;
  left: -3px;
  width: 7px;
  height: 1px;
}

.cropper-center:after {
  top: -3px;
  left: 0;
  width: 1px;
  height: 7px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.cropper-face {
  top: 0;
  left: 0;
  background-color: #fff;
}

.cropper-line {
  background-color: #39f;
}

.cropper-line.line-e {
  top: 0;
  right: -3px;
  width: 5px;
  cursor: e-resize;
}

.cropper-line.line-n {
  top: -3px;
  left: 0;
  height: 5px;
  cursor: n-resize;
}

.cropper-line.line-w {
  top: 0;
  left: -3px;
  width: 5px;
  cursor: w-resize;
}

.cropper-line.line-s {
  bottom: -3px;
  left: 0;
  height: 5px;
  cursor: s-resize;
}

.cropper-point {
  width: 5px;
  height: 5px;
  opacity: 0.75;
  background-color: #39f;
}

.cropper-point.point-e {
  top: 50%;
  right: -3px;
  margin-top: -3px;
  cursor: e-resize;
}

.cropper-point.point-n {
  top: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: n-resize;
}

.cropper-point.point-w {
  top: 50%;
  left: -3px;
  margin-top: -3px;
  cursor: w-resize;
}

.cropper-point.point-s {
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: s-resize;
}

.cropper-point.point-ne {
  top: -3px;
  right: -3px;
  cursor: ne-resize;
}

.cropper-point.point-nw {
  top: -3px;
  left: -3px;
  cursor: nw-resize;
}

.cropper-point.point-sw {
  bottom: -3px;
  left: -3px;
  cursor: sw-resize;
}

.cropper-point.point-se {
  right: -3px;
  bottom: -3px;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  opacity: 1;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    width: 5px;
    height: 5px;
    opacity: 0.75;
  }
}

.cropper-point.point-se:before {
  position: absolute;
  right: -50%;
  bottom: -50%;
  display: block;
  width: 200%;
  height: 200%;
  content: ' ';
  opacity: 0;
  background-color: #39f;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMzTjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}

.cropper-hide {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}
</style>
