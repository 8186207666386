<!--
 * @Description: 素材库 面板
 * @Author: 王立俊
 * @Date: 2023-08-09 11:27:56
 * @LastEditTime: 2023-09-21 16:43:03
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
-->
<template>
  <!-- 内容 -->
  <div>
    <div class="editor-panel-content">
      <!-- 图标列表-->
      <div class="editor-panel-row">
        <span class="desc">图标</span>
        <div class="icon-styles">
          <img v-for="(item, index) in iconList" :key="index" :src="item.iconFile" alt="" @click="onChangeIcon(item)" />
        </div>
      </div>
    </div>
    <div class="editor-bottom-btn">
      <i class="iconfont icon-close" @click="handleClose" />
      <div class="title">素材</div>
      <i class="iconfont icon-success" @click="handleSuccess" />
    </div>
  </div>
</template>

<script>
import { insertImgFile } from '@/utils/util'
export default {
  name: 'IconPanel',
  data() {
    return {
      activeObj: {},
      size: 1,
      iconList: [],
    }
  },
  methods: {
    // 父组件传值
    show(list, activeObj) {
      this.iconList = list
      this.activeObj = activeObj
    },

    // 选择图标
    onChangeIcon(item) {
      const image = new Image()
      image.src = item.iconFile
      image.crossOrigin = 'anonymous'

      image.onload = () => {
        if (this.activeObj) {
          this.$emit('updateIcon', image)
        } else {
          this.$emit('onIcon', image)
        }
      }
    },

    // 关闭
    handleClose() {
      this.$emit('close')
    },
    // 成功
    handleSuccess() {
      this.$emit('success')
    },
  },
}
</script>

<style lang="scss" scoped>
.desc {
  color: #9ba2ac;
  font-size: 14px;
  margin-bottom: 10px;
}
.editor-panel-content {
  background-color: #f5f6f9;
  .editor-icon {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 20px 20px 20px;
    background-color: #ffffff;
    border-bottom: 1px solid #edeff3;
  }
  .editor-content-font {
    padding: 15px 20px;
    background-color: #ffffff;
    border-bottom: 1px solid #edeff3;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .editor-panel-row {
    padding: 15px 20px 20px 20px;
    background-color: #fff;
    border-bottom: 1px solid #edeff3;
    .icon-styles {
      padding-top: 10px;
      height: 150px;
      overflow-y: auto;
      i {
        font-size: 22px;
        padding: 6px;
      }
      .i-split {
        font-size: 20px;
        color: #edeff3;
      }
    }
  }
}

img {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 4px;
  margin-bottom: 4px;
  background: #f5f5f5;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
}
</style>
