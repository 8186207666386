/*
 * @Description:
 * @Author: 王立俊
 * @Date: 2023-08-08 19:16:30
 * @LastEditTime: 2023-09-26 09:59:32
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
 */
import request from '@/utils/request'

// 查询字体颜色列表
export function listColor(query) {
  return request({
    url: '/material/color/list',
    method: 'get',
    params: query,
  })
}
// 查询字体列表
export function listFonts(query) {
  return request({
    url: '/material/font/list',
    method: 'get',
    params: query,
  })
}
// 查询素材列表
export function listSvg(query) {
  return request({
    url: '/material/icon/list',

    method: 'get',
    params: query,
  })
}
// 获取商品详情
export function getGoodDetail(id) {
  return request({
    url: '/bimei/commodity/mini/json/' + id,
    method: 'get',
  })
}
// 获取上次Json
export function getGoodJson(orderId) {
  return request({
    url: '/mini/order/order/json',
    method: 'get',
    params: { id: orderId },
  })
}
// 获取引导信息
export function postOperationConfig(data) {
  return request({
    url: '/mini/common/operation/config',
    method: 'post',
    data,
  })
}
// 上传文件
export function uploadFile(data) {
  return request({
    url: '/common/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
}
