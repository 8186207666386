<!--
 * @Description:样式面板
 * @Author: 王立俊
 * @Date: 2023-06-17 09:49:04
 * @LastEditTime: 2023-09-26 15:36:23
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
-->
<template>
  <!-- 内容 -->
  <div>
    <div v-show="!visibleFontShow" class="editor-panel-content">
      <!-- 字号 -->
      <div class="editor-fontSize">
        <div class="desc">字号</div>
        <van-slider
          v-model="styles.fontSize"
          class="slider"
          :min="12"
          :max="600"
          bar-height="4px"
          button-size="14px"
          active-color="#4391FC"
          @input="onChangeSize"
        />
        <div style="color: #4391fc">{{ styles.fontSize }}</div>
      </div>
      <!-- 样式 粗体 斜体 下划线 中划线 -->
      <div class="editor-panel-row">
        <span class="desc">格式</span>
        <div class="font-styles">
          <i
            :class="['iconfont icon-line-bold', { 'style-active': styles.bold }]"
            @click="stylesChange('fontWeight')"
          />
          <i
            :class="['iconfont icon-line-italics', { 'style-active': styles.italics }]"
            @click="stylesChange('fontStyle')"
          />
          <i class="iconfont icon-align-center" @click="stylesChange('textAlign', 'center')" />
          <i class="iconfont icon-align-left" @click="stylesChange('textAlign', 'left')" />
          <i class="iconfont icon-align-right" @click="stylesChange('textAlign', 'right')" />
        </div>
      </div>
      <!-- 字体 -->
      <div class="editor-content-font" @click="showFontPanel">
        <!-- 字体选择 -->
        <span>{{ '请选择字体' }}</span>
        <i class="iconfont icon-screening i-screening" />
      </div>
    </div>
    <!-- 字体列表 -->
    <div v-if="visibleFontShow" class="editor-font-panel">
      <div class="editor-font-content">
        <div
          v-for="(item, index) in fontList"
          :key="index"
          class="editor-font-item"
          @click="onChangeFont(item, item.psName)"
        >
          <div v-if="!item.preview" class="font-item">{{ item.name }}</div>
          <div v-else class="font-item" :style="`background-image:url('${item.preview}');`">
            {{ !item.preview ? item : '' }}
          </div>
          <van-loading v-show="item.loading" type="spinner" size="15px" />
          <i v-if="item.isChange" class="iconfont icon-confirm i-confirm" />
        </div>
      </div>
      <div class="editor-font-fixed" @click="visibleFontShow = false">收起</div>
    </div>
    <div v-show="!visibleFontShow" class="editor-bottom-btn">
      <i class="iconfont icon-close" @click="handleClose" />
      <div class="title">字体样式</div>
      <i class="iconfont icon-success" @click="handleSuccess" />
    </div>
  </div>
</template>

<script>
import FontFaceObserver from 'fontfaceobserver'
import { Toast } from 'vant'
export default {
  name: 'StylesTextPanel',
  props: {},
  data() {
    return {
      activeObj: {},
      fabricInstance: null,
      loading: false,
      visibleFontShow: false, // 字体列表弹窗
      activeFont: '', //选择字体
      fontList: [],
      styles: {
        fontSize: 12,
        bold: '',
        italics: '',
        textAlign: 'left',
      },
    }
  },
  methods: {
    // 父组件传值
    show(activeObj, fabricInstance, list) {
      this.styles = {
        fontSize: Math.ceil(activeObj.fontSize * (activeObj.scaleX || 1)),
        bold: activeObj.fontWeight,
        italics: activeObj.fontStyle,
        textAlign: activeObj.textAlign,
      }
      this.activeObj = activeObj
      this.fabricInstance = fabricInstance
      this.fontList = list
    },

    // 打开字体面板
    showFontPanel() {
      this.visibleFontShow = true
    },
    // 选择字体
    async onChangeFont(item, fontName) {
      if (this.loading) return
      if (!fontName) return

      try {
        item.loading = true
        this.loading = true
        // 字体加载
        const font = new FontFaceObserver(fontName)
        font.load(null, 100000).then(() => {
          this.fabricInstance.getActiveObject().set('fontFamily', fontName)
          this.fabricInstance.requestRenderAll()
          item.loading = false
          this.loading = false
        })
      } catch (error) {
        item.loading = false
        this.loading = false
        Toast('加载字体出错')
      }
    },
    // 修改字号
    onChangeSize(val) {
      const size = Math.ceil(val / (this.activeObj.scaleX || 1))
      this.$emit('onChangeText', { key: 'fontSize', val: size })
    },
    // 修改样式
    stylesChange(key, value) {
      let val = null
      if (key === 'textAlign') {
        this.styles.textAlign = value
        val = value
      } else if (key === 'fontWeight') {
        val = this.styles.bold === 'normal' ? 'bold' : 'normal'
        this.styles.bold = val
      } else if (key === 'fontStyle') {
        val = this.styles.italics === 'normal' ? 'italic' : 'normal'
        this.styles.italics = val
      }
      this.$emit('onChangeText', { key, val })
    },
    // 关闭
    handleClose() {
      this.$emit('close')
    },
    // 成功
    handleSuccess() {
      this.$emit('success')
    },
  },
}
</script>

<style lang="scss" scoped>
.desc {
  width: 40px;
  min-width: 40px;
  color: #9ba2ac;
  font-size: 13px;
}
.editor-panel-content {
  background-color: #f5f6f9;
  .editor-fontSize {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background-color: #ffffff;
    border-bottom: 1px solid #edeff3;
  }
  .editor-content-font {
    padding: 15px 20px;
    font-size: 12px;
    background-color: #ffffff;
    border-bottom: 1px solid #edeff3;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .i-screening {
      font-size: 14px;
      color: #000;
    }
  }

  .editor-panel-row {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background-color: #fff;
    border-bottom: 1px solid #edeff3;
    .font-styles {
      i {
        font-size: 20px;
        padding: 0 6px 6px 0;
      }
    }
  }
}

.editor-font-panel {
  height: 240px;
  min-height: 240px;
  background-color: #ffffff;
  .editor-font-content {
    height: 200px;
    overflow-y: auto;

    .editor-font-item {
      display: flex;
      font-size: 16px;
      align-items: center;
      justify-content: space-between;
      padding: 10px 30px;
      .font-item {
        background-size: cover;
        background-position: center center;
        height: 18px;
        width: 120px;
        color: #333942;
        font-size: 12px;
        text-align: center;
      }
      .i-confirm {
        font-size: 18px;
        color: #4391fc;
      }
    }
  }
  .editor-font-fixed {
    position: fixed;
    bottom: 0;
    color: #000000;
    width: 100%;
    font-size: 14px;
    font-weight: 550;
    text-align: center;
    border-top: 1px solid #edeff3;
    padding: 10px 0;
  }
}
.slider {
  margin: 0 10px 0 0;
}
</style>
