/*
 * @Description:
 * @Author: 王立俊
 * @Date: 2023-06-12 18:02:47
 * @LastEditTime: 2023-08-28 20:16:52
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
 */
import Vue from 'vue'
import App from './App.vue'

import './assets/fonts/iconfont/iconfont.css'
// 自定义字体文件
import './assets/fonts/font.css'

import Button from 'vant/lib/button'
import Dialog from 'vant/lib/dialog'
import ImagePreview from 'vant/lib/image-preview'
import Loading from 'vant/lib/loading'
import Overlay from 'vant/lib/overlay'
import Slider from 'vant/lib/slider'
import Toast from 'vant/lib/toast'
import 'vant/lib/button/style'
import 'vant/lib/dialog/style'
import 'vant/lib/image-preview/style'
import 'vant/lib/loading/style'
import 'vant/lib/slider/style'
import 'vant/lib/overlay/style'
import 'vant/lib/toast/style'
// import { Button, Slider, Loading, Overlay, Toast, ImagePreview } from 'vant'
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Slider)
Vue.use(Loading)
Vue.use(Overlay)
Vue.use(Toast)
Vue.use(ImagePreview)
Vue.config.productionTip = false
new Vue({
  render: h => h(App)
}).$mount('#app')
