<!--
 * @Description:字体颜色 面板
 * @Author: 王立俊
 * @Date: 2023-06-14 11:47:56
 * @LastEditTime: 2023-08-23 15:59:33
 * @LastEditors: 王立俊
 * 去没人的岛 摸鲨鱼的角.
-->
<template>
  <div class="editor-panel-color">
    <div class="editor-content-color">
      <div class="color-box">
        <div
          v-for="(color, index) in colorList"
          :key="index"
          class="color"
          :style="{ backgroundColor: color.colorPlate }"
          @click="onChange(color.colorPlate, index)"
        >
          <div :class="activeColor == index ? 'active' : ''"></div>
        </div>
      </div>
    </div>
    <div class="editor-bottom-btn">
      <i class="iconfont icon-close" @click="handleClose" />
      <div class="title">颜色</div>
      <i class="iconfont icon-success" @click="handleSuccess" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorPanel',
  props: {},
  data() {
    return {
      activeColor: null,
      colorList: []
    }
  },
  methods: {
    // 传值
    show(colors) {
      this.colorList = colors
    },
    // 删除所选颜色
    delete() {
      this.activeColor = null
    },
    // 选择颜色
    onChange(val, index) {
      this.activeColor = index
      this.$emit('onColor', val)
    },
    // 关闭
    handleClose() {
      this.$emit('close')
    },
    // 成功
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-panel-color {
  .editor-content-color {
    padding: 9px 15px;
    background-color: #ffffff;
    border-bottom: 1px solid #edeff3;
    display: flex; /* 将子元素横向排列 */
    align-items: center;

    .color-box {
      padding: 6px;
      display: flex; /* 将子元素横向排列 */
      white-space: nowrap; /* 避免子元素在父元素内换行 */
      overflow: hidden;
      overflow-x: scroll;
      ::-webkit-scrollbar {
        width: 0 !important;
      }
      ::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
      .color {
        position: relative;
        margin: 6px;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        border-radius: 50%;
      }
      .active {
        position: absolute;
        top: -4px;
        left: -4px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid #4391fc;
      }
    }
  }
}
</style>
